import React, { PropsWithChildren, useEffect } from 'react';
import { computedStylesRecoil, getTenantTheme } from './recoil/computedStyles.recoil';
import { useRecoilState, useRecoilValue } from 'recoil';
import { defaultTenantTheme } from '../../../../configs/defaultTenant';
import { useTenantSelf } from '../../hooks/tenant/useTenantSelf';
import { tenantSelector } from '../../state/tenantState';
import { useCreateUIConfig } from './hooks/useCreateUIConfig';

export interface IComputedStylesProvider extends PropsWithChildren {
    forceDefaultTheme?: boolean;
}

export const ComputedStylesProvider: React.FC<IComputedStylesProvider> = (props) => {
    useTenantSelf(); // initialize tenant and sets it to recoil

    const tenant = useRecoilValue(tenantSelector);

    const { children, forceDefaultTheme = false } = props;

    const { createUIConfig } = useCreateUIConfig();

    const [computedStyles, setComputedStyles] = useRecoilState(computedStylesRecoil);

    const tenantTheme = useRecoilValue(getTenantTheme);

    useEffect(() => {
        if (!computedStyles.theme && tenant) {
            // eslint-disable-next-line no-console
            // console.log('createUIConfig', createUIConfig().colors.applications);
            setComputedStyles((currVal) => {
                return {
                    ...currVal,
                    theme: forceDefaultTheme ? defaultTenantTheme() : createUIConfig(),
                };
            });
        }
    }, [computedStyles.theme, tenant, createUIConfig, forceDefaultTheme, setComputedStyles]);

    // eslint-disable-next-line no-console
    // console.log('tenantTheme', tenantTheme);

    return <>{tenantTheme !== null && children}</>;
};
