import React, { useCallback, useMemo, useState } from 'react';
import { TextField } from '../../../../general/controls/texfield/TextField';
import { Button } from '../../../../general/controls/button/Button';
import { ArrowPathIcon, KeyIcon } from '@heroicons/react/16/solid';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { tenantSelector } from '../../../../general/state/tenantState';
import { useMFASubmitCode } from '../hooks/useMFASubmitCode';
import { MFARequiredSelector } from '../state/login.state';
import Link from 'next/link';
import { Infobox } from '../../../../general/display/infobox/Infobox';
import { useTranslationApply } from '../../../../general/Translations/hooks/useTranslationApply';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';

interface IMFAForm {}

export const MFAForm: React.FC<IMFAForm> = () => {
    const setMFARequired = useSetRecoilState(MFARequiredSelector);

    const [code, setCode] = useState('');

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const { submitCode } = useMFASubmitCode();

    const tenant = useRecoilValue(tenantSelector);

    const [isLoading, setIsLoading] = useState<null | boolean>(null);

    const isFilled = useMemo(() => {
        return code !== '';
    }, [code]);

    const handleSubmit = useCallback(
        (MFACode: string, noErrorMessage = false) => {
            void submitCode(MFACode, noErrorMessage)
                .then((res) => {
                    if (res !== undefined) {
                        // Success
                        setIsLoading(false);
                        setCode('');
                        // Off the Code Ui
                        setMFARequired(null);
                    }
                })
                .catch((reason) => {
                    setMFARequired(null);
                    console.error('Error on MFA Submit', reason);
                });
        },
        [setMFARequired, submitCode]
    );

    return (
        <>
            {tenant && (
                <div
                    className="relative flex w-full flex-col items-center gap-6"
                    data-tenant-name={`login-MFA-${tenant.name}`}
                >
                    <Infobox>
                        <span
                            {...applyTranslation({
                                key: 'profileLogin2FACodeInfo',
                            })}
                        >
                            {getT('profileLogin2FACodeInfo') ??
                                'Den Code finden Sie in der E-Mail, die wir Ihnen zugesendet haben.'}
                        </span>
                    </Infobox>

                    <div className="relative flex w-[140px]">
                        <TextField
                            className=""
                            classNameInput="font-mono"
                            label="Code"
                            labelTranslationKey="profileLoginCodeLabel"
                            autocomplete={`name${String(Math.random())}`}
                            id={`MFA-${tenant.name}`}
                            testId="login-MFACode"
                            maxLength={6}
                            value={code}
                            onPaste={(value) => {
                                // \D matches all non-digit characters
                                return value.replace(/\D/g, '');
                            }}
                            // remove everything expect numbers
                            manipulation={(value: string): string => {
                                // Remove everything except numbers
                                return value.replace(/\D/g, ''); // \D matches all non-digit characters
                            }}
                            onChange={(newValue) => {
                                setCode(newValue);

                                // Check that the code is 6 digits long with regex
                                const codeRegex = /^[0-9]{6}$/;
                                if (codeRegex.test(newValue)) {
                                    handleSubmit(newValue, true);
                                }
                            }}
                            fancyNumbers={true}
                            defaultValue={code}
                        />
                    </div>

                    {/* Sign In Button */}
                    <div className="relative flex flex-col items-center justify-center">
                        <Button
                            color="primary"
                            testId="login-submit"
                            iconEnd={isLoading ? <ArrowPathIcon /> : <KeyIcon />}
                            iconSpin={isLoading === true}
                            disabled={!isFilled}
                            onClick={() => {
                                if (isFilled) {
                                    handleSubmit(code);
                                }
                            }}
                            additionalCss="w-max justify-center items-center"
                        >
                            <span
                                {...applyTranslation({
                                    key: 'profileLoginSignInMFACodeButton',
                                })}
                            >
                                {getT('profileLoginSignInMFACodeButton') ?? 'Code übermitteln'}
                            </span>
                        </Button>
                    </div>

                    <div className="relative mt-4 flex flex-col gap-1">
                        <Link
                            className="text-sm text-neutral-500 hover:underline"
                            href="/login"
                            onClick={() => {
                                setMFARequired(null);
                            }}
                        >
                            <span
                                {...applyTranslation({
                                    key: 'profileLoginBackToLoginLink',
                                })}
                            >
                                {getT('profileLoginBackToLoginLink') ?? 'Zurück zum Login'}
                            </span>
                        </Link>
                    </div>
                </div>
            )}
        </>
    );
};
