import { useCallback } from 'react';
import { login, logout, TLoginResponse } from '../../api/auth/login';

export const useAuth = () => {
    const doAuth = useCallback(
        async (
            username: string,
            password: string,
            tenant?: string
        ): Promise<TLoginResponse | false> => {
            try {
                return await login({ username, password, tenant });
            } catch (e) {
                return false;
            }
        },
        []
    );

    const logOut = useCallback(async () => {
        return logout();
    }, []);

    return { doAuth, logOut };
};
