import React, { PropsWithChildren, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';
import { InformationCircleIcon } from '@heroicons/react/16/solid';
import { useTranslationApply } from '../../Translations/hooks/useTranslationApply';
import { useTranslation } from '../../Translations/hooks/useTranslation';
import { useThemeStyles } from '../../theme/TenantTheme/hooks/useThemeStyles';

export interface IInfobox extends PropsWithChildren {
    infoText?: string;
    testId?: string;
    small?: boolean;
    translationKey?: string;
}

export const Infobox: React.FC<IInfobox> = (props) => {
    const { infoText, testId, small, children, translationKey } = props;

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const { applyStyles } = useThemeStyles();

    const tenantTheme = useRecoilValue(getTenantTheme);

    const fontSize = useMemo(() => {
        return small ? 'text-sm' : 'text-base';
    }, [small]);

    const padding = useMemo(() => {
        return small ? 'p-2' : 'p-4';
    }, [small]);

    return (
        <div
            className={`relative h-max w-full ${padding}`}
            style={{
                ...applyStyles({
                    bgColor: tenantTheme.colors.applications.infoBox.background.DEFAULT,
                }),
            }}
        >
            <div
                className="relative flex flex-row justify-between gap-6 tracking-wide text-primary-dark"
                data-test-id={`${testId ?? ''}-Text`}
                style={{
                    color: tenantTheme.colors.primary.dark,
                }}
            >
                <span
                    className={fontSize}
                    {...applyTranslation({
                        key: translationKey,
                    })}
                >
                    {(translationKey && getT(translationKey)) ?? infoText ?? children}
                </span>

                <InformationCircleIcon
                    className="h-5 w-max text-blue-500 min-w-5"
                    data-test-id={`${testId ?? ''}-icon`}
                />
            </div>
        </div>
    );
};
