import React, { useCallback, useState } from 'react';
import { TextField } from '../../../../general/controls/texfield/TextField';
import { CloudbarLink } from '../../../../general/typo/Links/CloudbarLink';
import { Button } from '../../../../general/controls/button/Button';
import { ArrowPathIcon, CheckIcon } from '@heroicons/react/16/solid';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { tenantSelector } from '../../../../general/state/tenantState';
import { IUserFromDB } from '../../../../../interfaces/IUser';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { MFARequiredSelector } from '../state/login.state';
import { useAuth } from '../../../../general/Auth/hooks/useAuth';
import { useLoginHelper } from '../hooks/useLoginHelper';
import { useApi } from '../../../../general/hooks/api/useApi';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';
import { useTranslationApply } from '../../../../general/Translations/hooks/useTranslationApply';

interface ISignInForm {}

export const SignInForm: React.FC<ISignInForm> = () => {
    const { doAuth } = useAuth();
    const { get } = useApi();
    const { loginRouter } = useLoginHelper();

    const { getT } = useTranslation();
    const { applyTranslation } = useTranslationApply();

    const setMFARequired = useSetRecoilState(MFARequiredSelector);
    const tenant = useRecoilValue(tenantSelector);

    const [isLoading, setIsLoading] = useState<null | boolean>(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const loginExec = useCallback(() => {
        setIsLoading(true);

        doAuth(username, password)
            .then(async (loginResponse) => {
                // eslint-disable-next-line no-console
                console.log('loginResponse', loginResponse);
                if (loginResponse && loginResponse.success) {
                    if (loginResponse && loginResponse.success && loginResponse.MFAToken) {
                        // ### MFA Login Routine
                        // Break and wait for MFA code / comes now via E-Mail of user.
                        // sendgridOrder2FATemplateID must be set

                        setMFARequired(loginResponse.MFAToken);
                        setIsLoading(false);
                    } else {
                        // ### Default Login Routine
                        setIsLoading(false);
                        setMFARequired(null);
                        const whoAmI = await get<IUserFromDB>(
                            `/auth/whoAmI?t=${dayjs().format('hhmmSSS')}`
                        );

                        if (whoAmI) {
                            loginRouter(
                                true,
                                whoAmI?.roles?.some((role) => role.admin)
                            );
                            return true;
                        }
                        loginRouter(false, false);
                        return false;
                    }
                }

                if (!loginResponse) {
                    toast.error(
                        getT('toastErrorLoginNotPossibleWithThisData') ??
                            'Login mit diesen Daten nicht möglich',
                        {
                            toastId: 'toast-login-error',
                        }
                    );
                }
                setIsLoading(false);
                return { success: false, admin: false };
            })

            .catch((reason: { response: { status: number; statusText: string } }) => {
                toast.error(
                    `${getT('toastErrorLoginError') ?? 'Login Fehler'} ${
                        reason.response.statusText ?? ''
                    }`
                );
                setIsLoading(false);
            });
    }, [doAuth, get, getT, loginRouter, password, setMFARequired, username]);

    return (
        <>
            {tenant && (
                <div
                    className="relative flex w-full flex-col items-center gap-6"
                    data-tenant-name={`login-${tenant.name}`}
                >
                    <TextField
                        label="E-Mail Addresse oder Username"
                        // labelTranslationKey="profileLoginEmailOrUsernameLabel"
                        // validation="email"
                        validationMessage="Kombination ungültig"
                        showValidation={false}
                        testId="login-username"
                        autocomplete={`username-${tenant.name}`}
                        id={`username-${tenant.name}`}
                        value={username}
                        onChange={(newValue) => {
                            setUsername(newValue);
                        }}
                        onKeyDown={(e) => {
                            // Check if e keycode = enter
                            if (e.code === 'Enter' && password !== '' && username !== '') {
                                loginExec();
                            }
                        }}
                    />
                    <TextField
                        label="Passwort"
                        // labelTranslationKey="profileLoginPasswordLabel"
                        validation="password"
                        type="password"
                        validationMessage="Kombination ungültig"
                        showValidation={false}
                        autocomplete={`password-${tenant.name}`}
                        id={`password-${tenant.name}`}
                        testId="login-password"
                        value={password}
                        onChange={(newValue) => {
                            setPassword(newValue);
                        }}
                        onKeyDown={(e) => {
                            // Check if e keycode = enter
                            if (e.code === 'Enter' && password !== '' && username !== '') {
                                loginExec();
                            }
                        }}
                    />

                    {/* Forgot PW Link */}
                    <div className="relative flex w-full items-end justify-end">
                        <CloudbarLink
                            href="/forgotpassword"
                            fontSize="text-base"
                            fontWeight="medium"
                            color="primaryLight"
                            testId="login-forgot-pw"
                        >
                            <span
                                {...applyTranslation({
                                    key: 'profileLoginForgotPasswordLink',
                                })}
                            >
                                {getT('profileLoginForgotPasswordLink') ?? 'Passwort vergessen?'}
                            </span>
                        </CloudbarLink>
                    </div>

                    {/* Sign In Button */}
                    <div className="relative flex flex-col items-center justify-center">
                        <Button
                            color="primary"
                            testId="login-submit"
                            iconEnd={isLoading ? <ArrowPathIcon /> : <CheckIcon className="h-5" />}
                            iconSpin={isLoading === true}
                            disabled={isLoading === true || username === '' || password === ''}
                            onClick={() => {
                                loginExec();
                            }}
                            additionalCss="w-max justify-center items-center"
                        >
                            <span
                                {...applyTranslation({
                                    key: 'profileLoginSignInButton',
                                })}
                            >
                                {getT('profileLoginSignInButton') ?? 'Einloggen'}
                            </span>
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
