export interface ITranslationStatic {
    [key: string]: {
        en: string;
        de: string;
    };
}

/**
 * @Deprecated: Use the new translation system with the useLocalisation hook.
 */
export const translationsMap: ITranslationStatic = {
    // Footer
    contactUs: {
        en: 'Contact us!',
        de: 'Kontaktieren Sie uns!',
    },
    imprint: {
        en: 'Imprint',
        de: 'Impressum',
    },
    privacyPolicy: {
        en: 'Privacy Policy',
        de: 'Datenschutz',
    },
    privacyGtc: {
        en: 'GTC',
        de: 'AGB',
    },
    anyQuestionsLabel: {
        en: 'Do you have questions?',
        de: 'Haben Sie Fragen?',
    },

    //Login
    loginError: {
        en: 'Login Fehler.',
        de: 'Login Fehler.',
    },
    signIn: {
        en: 'Sign in',
        de: 'Einloggen',
    },
    signInMFACode: {
        en: 'Sign in with MFA code',
        de: 'Code übermitteln',
    },
    forgotPw: {
        en: 'Forgot password?',
        de: 'Passwort vergessen?',
    },
    orForwardWith: {
        en: 'or forward with',
        de: 'Oder weiter mit',
    },

    //Header
    welcome: {
        en: 'Welcome,',
        de: 'Herzlich willkommen,',
    },
    menu: {
        en: 'Menu',
        de: 'Menü',
    },
    languageDE: {
        en: 'German',
        de: 'Deutsch',
    },
    languageEN: {
        en: 'English',
        de: 'Englisch',
    },
    toCart: {
        en: 'Cart',
        de: 'Zum Warenkorb',
    },

    //Header Dropdown
    logout: {
        en: 'Logut',
        de: 'Ausloggen',
    },
    myAccount: {
        en: 'My Account',
        de: 'Mein Konto',
    },

    //Button
    toArticle: {
        en: 'Go to article',
        de: 'Zum Artikel',
    },

    //Headlines
    orderingCentre: {
        en: 'Ordering centre',
        de: 'Bestellcenter',
    },
    myFavourites: {
        en: 'My Favourites',
        de: 'Meine Favoriten',
    },
    favourites: {
        en: 'Favourites',
        de: 'Favoriten',
    },
    cart: {
        en: 'Cart',
        de: 'Warenkorb',
    },
    wishlist: {
        en: 'Wishlist',
        de: 'Merkliste',
    },
    drafts: {
        en: 'Drafts',
        de: 'Entwürfe',
    },
    all: {
        en: 'All',
        de: 'Alle',
    },
    history: {
        en: 'History',
        de: 'Bestellhistorie',
    },
    orders: {
        en: 'Orders',
        de: 'Bestellungen',
    },
    redeem: {
        en: 'Redeem',
        de: 'Einlösen',
    },
    order: {
        en: 'Order',
        de: 'Warenkorb bestellen',
    },
    delete: {
        en: 'Delete',
        de: 'Löschen',
    },
    redeemVoucher: {
        en: 'Redeem Voucher code',
        de: 'Gutschein-Code einlösen',
    },
    browseArticles: {
        en: 'Browse articles',
        de: 'Artikel durchsuchen',
    },
    addWatchList: {
        en: 'Add to watchlist',
        de: 'Auf die Merkliste',
    },
    addShoppingCart: {
        en: 'Add to shopping cart',
        de: 'In den Warenkorb',
    },
    actionFor: {
        en: 'Action for',
        de: 'Aktion für',
    },
    searchOrders: {
        en: 'Browse Orders',
        de: 'Bestellungen durchsuchen',
    },
    searchCart: {
        en: 'Browse Cart',
        de: 'Warenkorb durchsuchen',
    },
    searchForArticle: {
        en: 'Search for article...',
        de: 'Nach Artikel suchen...',
    },
    article: {
        en: 'Article',
        de: 'Artikel',
    },
    sublineCart: {
        en: 'All the products you want to order are listed here.',
        de: 'Hier werden alle Produkte gelistet, die du bestellen möchtest.',
    },
    sublineSaved: {
        en: 'All products that you have saved are listed here.',
        de: 'Hier werden alle Produkte gelistet, die du gespeichert hast.',
    },
    sublineDrafts: {
        en: 'All your current drafts. Drafts are products, which never finsihed the editing process.',
        de: 'Hier werden alle deine aktuellen Entwürfe gelistet. Entwürfe sind Produkte, die den Bearbeitungsprozess nie abgeschlossen haben.',
    },
    sublineAll: {
        en: 'Both active and saved products are listed here.',
        de: 'Hier werden sowohl aktive als auch gespeicherte Produkte gelistet.',
    },
    sublineHistory: {
        en: 'All information on orders placed is displayed here. It is also possible to reorder or edit products.',
        de: 'Hier werden alle Informationen zu getätigten Bestellungen angezeigt. Ebenfalls besteht die Möglichkeit, Produkte erneut zu bestellen oder zu bearbeiten.',
    },

    //Cart
    modificationDate: {
        en: 'Modification date',
        de: 'Änderungsdatum',
    },
    amount: {
        en: 'Amount',
        de: 'Menge',
    },
    price: {
        en: 'Price',
        de: 'Preis',
    },

    //CartItem
    product: {
        en: 'Product',
        de: 'Produkt',
    },
    only: {
        en: 'only',
        de: 'nur',
    },
    available: {
        en: 'available',
        de: 'verfügbar',
    },
    copy: {
        en: 'Copy',
        de: 'Copieren',
    },
    edit: {
        en: 'Edit',
        de: 'Bearbeiten',
    },

    //OrderHistoryList
    auftraege: {
        en: 'Orders',
        de: 'Aufträge',
    },
    dateOfOrder: {
        en: 'Date of Order',
        de: 'Bestelldatum',
    },
    status: {
        en: 'Status',
        de: 'Status',
    },

    //TotalCost
    subtotal: {
        en: 'Subtotal',
        de: 'Zwischensumme',
    },
    shippingCosts: {
        en: 'Shipping costs',
        de: 'Versandkosten',
    },
    valueAddedTax: {
        en: 'Value-added tax',
        de: 'Mehrwertsteuer',
    },
    noteTaxNet: {
        de: 'Alle Preise sind Nettopreise zzgl. gesetzlicher MwSt.',
        en: 'All prices are net prices plus statutory VAT.',
    },
    totalAmount: {
        en: 'Total amount',
        de: 'Gesamtsumme',
    },

    //ProductDetail
    choose: {
        en: 'choose',
        de: 'auswählen',
    },
    pricelistNotConfigured: {
        en: 'Admin-Info: Price list not configured.',
        de: 'Admin-Info: Preisliste nicht konfiguriert.',
    },
    productNotAvailable: {
        en: 'Product is not available at the moment.',
        de: 'Das Produkt ist derzeit nicht bestellbar.',
    },

    //Legal Page
    legal: {
        en: 'Legal Notice',
        de: 'Impressum',
    },
    legalNotice: {
        en: 'Legal Notice',
        de: 'Rechtliche Hinweise',
    },
    privacy: {
        en: 'Privacy Policy',
        de: 'Datenschutz',
    },
};
