import { useCallback, useMemo } from 'react';
import { translationsMap } from '../../../../data/translationsMap';
import { useRecoilValue } from 'recoil';
import { languageSelector } from '../../state/appState';

export interface ITranslationParsed {
    [key: string]: string;
}

/**
 * @deprecated - use useTranslation instead
 * @param languageOverwrite
 */
export const useLocalisation = (languageOverwrite?: string) => {
    const languageState = useRecoilValue(languageSelector);

    const languageCode = useMemo(() => {
        const language = languageOverwrite ?? languageState ?? navigator.language ?? 'en';

        const cultureCodeRegEx = /\w{2}-\w{2}/;

        let languageCodeFinal = language;
        if (language.match(cultureCodeRegEx)) {
            languageCodeFinal = language.split('-')[0] ?? 'en';
        }
        if (languageCodeFinal !== 'de' && languageCodeFinal !== 'en') {
            languageCodeFinal = 'en';
        }

        return languageCodeFinal;
    }, [languageOverwrite, languageState]);

    const translations = useMemo(() => {
        const mapped: ITranslationParsed = {};

        Object.keys(translationsMap).forEach((key) => {
            mapped[key] = (translationsMap?.[key]?.[languageCode] as string) ?? '';
        });

        return mapped;
    }, [languageCode]);

    const getT = useCallback(
        (key: string): string | null => {
            return translations[key] ?? null;
        },
        [translations]
    );

    return { translations, languageCode, getT };
};
