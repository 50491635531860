import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';
import Link from 'next/link';
import { imagePathSanitizer } from '../../../../helper/imagePathSanitizer';
import { useThemeStyles } from '../../theme/TenantTheme/hooks/useThemeStyles';

export interface ICompanyHeader {
    testId?: string;
    src?: string;
    companyName: string;
    companyClaim?: string;
    blockClick?: boolean;
}

export const CompanyHeader: React.FC<ICompanyHeader> = (props) => {
    const { testId, src, companyName, companyClaim, blockClick } = props;

    const tenantTheme = useRecoilValue(getTenantTheme);

    const imagePath = useMemo(() => {
        if (!src) return null;

        return imagePathSanitizer(src);
    }, [src]);

    const { applyStyles } = useThemeStyles();

    return (
        <div className="relative flex w-max flex-row items-center gap-2">
            {imagePath && tenantTheme.header?.showLogo && (
                <Link
                    href={blockClick ? '' : '/'}
                    className={`${blockClick ? 'pointer-events-none' : ''}`}
                >
                    <div className="flex flex-col items-center justify-center py-1 h-headerUpperDefault w-headerUpperDefault md:h-headerUpperMd md:w-headerUpperMd">
                        <img
                            src={imagePath}
                            alt={`${companyName} Logo`}
                            className="h-full w-full object-contain object-left"
                            data-test-id={`${testId ?? nanoid()}-${companyName}-logo`}
                        />
                    </div>
                </Link>
            )}
            {(tenantTheme.header?.showClaim || tenantTheme.header?.showCompanyName) && (
                <div className="relative flex flex-col items-start justify-center gap-0">
                    {tenantTheme.header.showCompanyName && (
                        <span
                            className={`hidden font-bold text-sm sm:flex`}
                            data-theme-color-key="colors.primary.light"
                            style={{
                                ...applyStyles({
                                    textColor:
                                        tenantTheme.colors.applications.headerLogo.text.companyName,
                                }),
                            }}
                        >
                            {companyName}
                        </span>
                    )}
                    {companyClaim && tenantTheme.header.showClaim && (
                        <span
                            className="hidden min-w-max text-xs sm:flex"
                            style={{
                                ...applyStyles({
                                    textColor:
                                        tenantTheme.colors.applications.headerLogo.text.claim,
                                }),
                            }}
                        >
                            {companyClaim}
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};
