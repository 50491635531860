import { apiPost } from '../api';

interface TLoginProps {
    username: string;
    password: string;
    tenant?: string;
}

export interface TLoginResponse {
    success: boolean;
    use2FA?: boolean;
    MFAToken?: string;
}

export const login = async (props: TLoginProps): Promise<TLoginResponse> => {
    return apiPost<TLoginResponse, TLoginProps>('/auth/login', props);
};

export const logout = async (): Promise<unknown> => {
    return apiPost('/auth/logout');
};
