import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { Headline } from '../../../general/typo/headline/Headline';
import { Button } from '../../../general/controls/button/Button';
import { CompanyHeader } from '../../../general/elements/companyHeader/CompanyHeader';
import { tenantSelector } from '../../../general/state/tenantState';
import { MFARequiredSelector } from './state/login.state';
import { SignInForm } from './elements/signInForm';
import { MFAForm } from './elements/MFAForm';
import { useTranslationApply } from '../../../general/Translations/hooks/useTranslationApply';
import { useTranslation } from '../../../general/Translations/hooks/useTranslation';
import { CompanyHeaderLogoOnly } from '../../../general/elements/companyHeader/CompanyHeaderLogoOnly';

export interface ILogIn {
    testId?: string;
    sideImg?: ILogInImage;
}

export interface ILogInImage {
    src: string | null;
    alt: string;
}

export const LogIn: React.FC<ILogIn> = (props) => {
    const MFARequired = useRecoilValue(MFARequiredSelector);

    const { testId, sideImg } = props;

    const tenantTheme = useRecoilValue(getTenantTheme);

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const tenant = useRecoilValue(tenantSelector);

    const headlineString = useMemo(() => {
        if (MFARequired) {
            return 'Zweistufige Authentifizierung';
        }

        return 'In Ihr Konto einloggen';
    }, [MFARequired]);

    const headlineTranslationKey = useMemo(() => {
        if (MFARequired) return 'profileLoginTwoFactorAuthentication';

        return 'profileLoginLoginToYourAccount';
    }, [MFARequired]);

    const showWideLogoLogin = useMemo(() => {
        return tenantTheme.header.showWideLogoLogin ?? false;
    }, [tenantTheme.header.showWideLogoLogin]);

    return (
        <div
            className={`relative flex h-screen w-screen ${
                sideImg ? 'md:grid md:flex-none md:grid-cols-12' : ''
            }`}
        >
            {/* Log In aka. Left Side */}
            <div className={`relative w-full ${sideImg ? 'md:col-span-5' : ''}`}>
                <div
                    className="relative my-auto flex h-full flex-col items-center justify-center"
                    data-test-id={`${testId ?? nanoid()}-leftSideWrapper`}
                >
                    <div
                        className={`relative flex w-full  max-w-xl flex-col items-center justify-center gap-8 px-5 xs:px-16 ${
                            sideImg ? 'md:px-6 lg:px-20 xl:px-24' : 'md:px-40 lg:px-52 xl:px-72'
                        }`}
                    >
                        {/* Logo */}
                        <div
                            className={`relative mb-8 flex h-[60px] flex-col  ${
                                sideImg
                                    ? 'items-start justify-start'
                                    : 'items-center justify-center'
                            }`}
                        >
                            {showWideLogoLogin && (
                                <CompanyHeaderLogoOnly src={tenantTheme.brand.logoWide?.url} />
                            )}

                            {!showWideLogoLogin && (
                                <CompanyHeader
                                    src={
                                        tenantTheme.brand?.logo.url ?? '/assets/logos/logoBlank.svg'
                                    }
                                    companyName={''}
                                    companyClaim={''}
                                    testId={testId}
                                    blockClick={true}
                                />
                            )}
                        </div>
                        <Headline
                            headline={headlineString}
                            translationKey={headlineTranslationKey}
                            type="h4"
                            testId={testId}
                        />
                        {/* E-Mail, PW, forgot PW, and SignIn Button */}
                        {tenant && !MFARequired && <SignInForm />}
                        {tenant && MFARequired && <MFAForm />}
                        {/* or continue with.. */}
                        <div className="relative hidden w-full flex-col items-center pt-4">
                            <div className="relative flex w-full flex-row items-center">
                                <div
                                    className="flex-1 h-[1px] bg-primary"
                                    style={{
                                        background: tenantTheme.colors.border.light,
                                    }}
                                />
                                <span className="px-2 text-xs">
                                    {' '}
                                    <span
                                        {...applyTranslation({
                                            key: 'profileLoginOrForwardWith',
                                        })}
                                    >
                                        {getT('profileLoginOrForwardWith') ?? 'Oder weiter mit'}
                                    </span>{' '}
                                </span>
                                <div
                                    className="flex-1 h-[1px] bg-primary"
                                    style={{
                                        background: tenantTheme.colors.border.light,
                                    }}
                                />
                            </div>

                            {/* Other Login Options Buttons */}
                            <div className="relative hidden items-end justify-end pt-5">
                                {/* TODO:  a way to overwrite color stylings to match */}
                                <Button
                                    onClick={() => {}}
                                    color="info"
                                    testId={`${testId ?? nanoid()}-azureLoginBtn`}
                                    additionalCss="w-max"
                                    iconStartOpen={
                                        <div className="h-5 w-5">
                                            <img
                                                src="/assets/logos/Azure-Active-Directory.svg"
                                                alt="Azure AD logo"
                                                aria-hidden={true}
                                            />
                                        </div>
                                    }
                                >
                                    Azure AD
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Image In aka. Right Side */}
            {sideImg && (
                <div
                    className="relative col-span-7 hidden h-full w-full aspect-[2/1] md:flex"
                    aria-hidden={true}
                >
                    <img
                        src={sideImg?.src ?? '/assets/mockImages/cloudbar-login.png'}
                        alt={sideImg?.alt ?? ''}
                        className="h-full w-full object-cover"
                    />
                </div>
            )}
        </div>
    );
};
