import { atom, selector } from 'recoil';

export interface ILoginState {
    MFARequired: string | null;
}

export const loginStateRecoil = atom<ILoginState>({
    key: `login-state-recoil`,
    default: {
        MFARequired: null,
    },
});

export const MFARequiredSelector = selector<string | null>({
    key: `mfa-required-selector`,
    get: ({ get }) => {
        const loginState = get(loginStateRecoil);
        return loginState.MFARequired;
    },
    set: ({ set }, newValue) => {
        set(loginStateRecoil, { MFARequired: newValue as unknown as string | null });
    },
});
