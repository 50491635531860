import type { NextPage } from 'next';
import React, { useEffect, useMemo, useState } from 'react';
import { LogIn } from '../src/components/frontend/areas/logIn/LogIn';
import { useAuth } from '../src/components/general/Auth/hooks/useAuth';
import { useRouter, useSearchParams } from 'next/navigation';
import { useRecoilValue } from 'recoil';
import { tenantSelector } from '../src/components/general/state/tenantState';
import { ComputedStylesProvider } from '../src/components/general/theme/ComputedStyles/ComputedStylesProvider';
import { imagePathSanitizer } from '../src/helper/imagePathSanitizer';
import Head from 'next/head';

const Login: NextPage = () => {
    const { logOut } = useAuth();

    const query = useSearchParams();
    const router = useRouter();

    const tenant = useRecoilValue(tenantSelector);

    useEffect(() => {
        if (query.has('logout')) {
            // Reset Theming
            localStorage.removeItem('theme');
            localStorage.removeItem('tenant');

            // Preserve deepLink when logging out
            const deepLinkParam = query.get('deepLink');
            const redirectUrl = deepLinkParam 
                ? `/login${deepLinkParam ? `?deepLink=${deepLinkParam}` : ''}`
                : '/login';
                
            void logOut().then(() => router.push(redirectUrl));
        }
    }, [logOut, query, router]);

    const [bgSrc, setBgSrc] = useState<null | string>(null);

    useEffect(() => {
        if (tenant && tenant.uiConfig?.brand?.loginBgImage?.url) {
            setBgSrc(imagePathSanitizer(tenant.uiConfig?.brand?.loginBgImage?.url) || null);
        }
    }, [tenant]);

    const tenantNameSanitized = useMemo((): string => {
        if (!tenant?.name) {
            return '';
        }

        // Write the first letter in Capital
        return tenant?.name.charAt(0).toUpperCase() + tenant?.name.slice(1);
    }, [tenant?.name]);

    return (
        <ComputedStylesProvider>
            {tenant && tenantNameSanitized && (
                <>
                    <Head>
                        <title>{tenant ? `${tenantNameSanitized} Login` : 'Login'}</title>{' '}
                    </Head>
                    <LogIn
                        sideImg={{
                            src: bgSrc,
                            alt: '',
                        }}
                    />
                </>
            )}
        </ComputedStylesProvider>
    );
};
export default Login;
