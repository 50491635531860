import { useCallback } from 'react';
import { IUIConfig } from '../interfaces/computedStyles.interface';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { defaultTenantTheme } from '../../../../../configs/defaultTenant';
import { tenantSelector } from '../../../state/tenantState';

export const useCreateUIConfig = () => {
    const tenant = useRecoilValue(tenantSelector);

    const defaultTheme = defaultTenantTheme();

    const createUIConfig = useCallback(() => {
        const locallyStoredTheme = localStorage.getItem('theme');

        // Always start with the basic defaultTheme to make sure all values are existing
        const newTheme: IUIConfig = _.cloneDeep(defaultTheme);

        if (tenant?.uiConfig) {
            // Merge the tenant uiConfig with the defaultTheme (Server side)
            _.merge(newTheme, tenant.uiConfig);
        } else if (locallyStoredTheme) {
            // Merge the locally stored theme with the defaultTheme (Client side)
            _.merge(newTheme, JSON.parse(locallyStoredTheme));
        }

        return newTheme;
    }, [defaultTheme, tenant?.uiConfig]);

    return { createUIConfig };
};
