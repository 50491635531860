import { useCallback } from 'react';
import { apiPost } from '../../../../general/api/api';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MFARequiredSelector } from '../state/login.state';
import { useLoginHelper } from './useLoginHelper';
import { toast } from 'react-toastify';
import { useLocalisation } from '../../../../general/hooks/translations/useLocalisation';
import { useEnvironment } from '../../../../general/hooks/helper/useEnvironment';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';

export const useMFASubmitCode = () => {
    const MFARequired = useRecoilValue(MFARequiredSelector);
    const { translations } = useLocalisation();
    const { loginUserRegular } = useLoginHelper();
    const setMFARequired = useSetRecoilState(MFARequiredSelector);

    const { isDevelopment } = useEnvironment();

    const { getT } = useTranslation();

    const submitCode = useCallback(
        async (code: string, noErrorMessage = false) => {
            // post: login2FA
            // body: {token: string, shortToken: string}

            if (!MFARequired) return;

            await apiPost('/auth/login2FA', { token: MFARequired, shortToken: code })
                .then((value) => {
                    const result = value as { success: boolean };

                    if (result.success) {
                        setTimeout(() => {
                            setMFARequired(null);
                        }, 1000);

                        void loginUserRegular();
                    } else {
                        if (!noErrorMessage) {
                            toast.error(`${translations.loginError ?? ''}`, {
                                toastId: 'toast-login-error',
                            });
                        }
                    }
                })
                .catch((reason) => {
                    if (!noErrorMessage) {
                        toast.error(
                            getT('toastErrorWhenTransmittingCode') ??
                                `Fehler bei der Übermittlung des Codes. ${
                                    isDevelopment ? `Reason: ${reason}` : ''
                                }`,
                            {
                                toastId: 'toast-login-error',
                                autoClose: isDevelopment ? 100000 : 5000,
                            }
                        );
                    }
                    console.error(reason);
                });
        },
        [
            MFARequired,
            getT,
            isDevelopment,
            loginUserRegular,
            setMFARequired,
            translations.loginError,
        ]
    );

    return {
        submitCode,
    };
};
