import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { IUserFromDB } from '../../../../../interfaces/IUser';
import dayjs from 'dayjs';
import { useApi } from '../../../../general/hooks/api/useApi';
import { getDecodedDeepLink } from '../../../../general/Auth/authUtil';

export const useLoginHelper = () => {
    const { get } = useApi();

    const router = useRouter();

    const loginRouter = useCallback(
        (success: boolean, isAdmin: boolean) => {
            if (success) {
                // Try to get deepLink from URL for both user types
                const urlParams = new URLSearchParams(window.location.search);
                const deepLink = urlParams.get('deepLink');

                if (deepLink) {
                    const decodedPath = getDecodedDeepLink(deepLink);
                    void router.push(decodedPath);
                    return;
                }
            }

            // For admin users with deepLink
            if (isAdmin) {
                // Let the admin index page handle redirection
                // This will then redirect to overview or tenants based on tenant type
                void router.push('/admin');
            } else {
                // Default user redirection
                void router.push('/');
            }
        },
        [router]
    );

    const loginUserRegular = useCallback(async () => {
        const whoAmI = await get<IUserFromDB>(`/auth/whoAmI?t=${dayjs().format('hhmmSSS')}`);

        if (whoAmI) {
            loginRouter(
                true,
                whoAmI?.roles?.some((role) => role.admin)
            );
            return true;
        }
        loginRouter(false, false);
    }, [get, loginRouter]);

    return { loginRouter, loginUserRegular };
};
