import { atom, DefaultValue, selector } from 'recoil';
import { nanoid } from 'nanoid';
import { IUser } from '../../../interfaces/IUser';

export type Language = 'de' | 'en';

export interface IAppState {
    language: Language;
    // showNavigationPanelItem: string | undefined;
    hasNewMessages: boolean;
    user?: IUser;
    categoryId?: string;
    productId?: string;
    search: string;
    wrapperAdditionalClasses: null | string;
    showNavigationPanel?: string;
    showSearch?: boolean;
    searchInputRef?: HTMLInputElement | null;
    selectedTab?: string;
    adminArea: string | null;
}

export const appStateRecoil = atom<IAppState>({
    key: `app-state-state`,
    default: {
        language: 'de',
        // showNavigationPanelItem: undefined,
        hasNewMessages: false,
        wrapperAdditionalClasses: null,
        search: '',
        selectedTab: undefined,
        adminArea: null,
    },
});

export const languageSelector = selector<Language>({
    key: 'languageSelector',
    get: ({ get }) => {
        return get(appStateRecoil).language;
    },
    set: ({ set }, newValue) =>
        set(
            appStateRecoil,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, language: newValue };
                  }
        ),
});

export const productIdSelector = selector<string | undefined>({
    key: 'productIdSelector',
    get: ({ get }) => {
        return get(appStateRecoil).productId;
    },
    set: ({ set }, newValue) =>
        set(
            appStateRecoil,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, productId: newValue };
                  }
        ),
});

export const userSelector = selector<IUser | undefined>({
    key: 'userSelector',
    get: ({ get }) => {
        return get(appStateRecoil).user;
    },
    set: ({ set }, newValue) =>
        set(
            appStateRecoil,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, user: newValue };
                  }
        ),
});

export const hasNewMessagesSelector = selector<boolean>({
    key: 'messagesSelector',
    get: ({ get }) => {
        return get(appStateRecoil).hasNewMessages;
    },
    set: ({ set }, newValue) =>
        set(
            appStateRecoil,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, hasNewMessages: newValue };
                  }
        ),
});

// export const showNavigationPanelItemSelector = selector<string | undefined>({
//     key: `getShowNavigationPanelItem/${nanoid()}`,
//     get: ({ get }): string | undefined => {
//         return get(appStateRecoil).showNavigationPanelItem;
//     },
//     set: ({ set }, newValue) =>
//         set(
//             appStateRecoil,
//             newValue instanceof DefaultValue
//                 ? newValue
//                 : (currVal) => {
//                       return { ...currVal, showNavigationPanelItem: newValue };
//                   }
//         ),
// });

export const getShowNavigationPanel = selector<string | undefined>({
    key: `get-show-nav-panel/${nanoid()}`,
    get: ({ get }): string | undefined => {
        return get(appStateRecoil).showNavigationPanel;
    },
});

export const getShowSearch = selector<boolean | undefined>({
    key: `get-show-mobile-search/${nanoid()}`,
    get: ({ get }): boolean | undefined => {
        return get(appStateRecoil).showSearch;
    },
});

export const headerSearchSelector = selector<string>({
    key: `header-search`,
    get: ({ get }): string => {
        return get(appStateRecoil).search ?? '';
    },
    set: ({ set }, newFilter) =>
        set(
            appStateRecoil,
            newFilter instanceof DefaultValue
                ? newFilter
                : (currVal) => {
                      return { ...currVal, search: newFilter };
                  }
        ),
});

export const searchInputRefSelector = selector<HTMLInputElement | null>({
    key: `search-input-ref`,
    get: ({ get }): HTMLInputElement | null => {
        return get(appStateRecoil).searchInputRef ?? null;
    },
    set: ({ set }, newFilter) =>
        set(
            appStateRecoil,
            newFilter instanceof DefaultValue
                ? newFilter
                : (currVal) => {
                      return { ...currVal, searchInputRef: newFilter };
                  }
        ),
});

export const selectedTabSelector = selector<string | undefined>({
    key: `selected-tab`,
    get: ({ get }): string | undefined => {
        return get(appStateRecoil).selectedTab;
    },
    set: ({ set }, newFilter) =>
        set(
            appStateRecoil,
            newFilter instanceof DefaultValue
                ? newFilter
                : (currVal) => {
                      return { ...currVal, selectedTab: newFilter };
                  }
        ),
});

export const adminAreaSelector = selector<string | null>({
    key: `admin-area`,
    get: ({ get }): string | null => {
        return get(appStateRecoil).adminArea;
    },
    set: ({ set }, newFilter) =>
        set(
            appStateRecoil,
            newFilter instanceof DefaultValue
                ? newFilter
                : (currVal) => {
                      return { ...currVal, adminArea: newFilter };
                  }
        ),
});

