import React, { MouseEventHandler, PropsWithChildren, useMemo } from 'react';
import Link from 'next/link';
import { nanoid } from 'nanoid';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';

export interface ICloudbarLink extends PropsWithChildren {
    href?: string;
    target?: string;
    fontWeight?: 'medium' | 'regular';
    fontSize?: 'text-sm' | 'text-base' | 'text-lg' | 'text-xs';
    testId?: string;
    color?: 'primary' | 'primaryLight';
    isButton?: boolean;
    onClick?: () => void;
}

export const CloudbarLink: React.FC<ICloudbarLink> = (props) => {
    const { href, target, color, isButton, fontWeight = 'medium', children, fontSize = 'text-base', testId } = props;

    const tenantTheme = useRecoilValue(getTenantTheme);

    const weightStyles = useMemo(() => {
        return fontWeight === 'medium' ? 'font-semibold' : '';
    }, [fontWeight]);

    const selectedColor = useMemo(() => {
        switch (color) {
            case 'primary':
                return tenantTheme.colors.primary.DEFAULT;
            case 'primaryLight':
                return tenantTheme.colors.primary.light;
        }
    }, [color, tenantTheme.colors.primary.DEFAULT, tenantTheme.colors.primary.light]);

    return (
        <>
            {!isButton ? (
                <Link
                    href={href ?? ''}
                    target={target ?? undefined}
                    className={`relative inline-flex text-primary underline transition-all hover:text-primary-light ${weightStyles} ${
                        fontSize as string
                    }`}
                    data-test-id={`${testId ?? nanoid()}-link`}
                    style={{
                        color: selectedColor,
                    }}
                >
                    {children}
                </Link>
            ) : (
                <button
                    onClick={props.onClick as MouseEventHandler}
                    className={`relative inline-flex text-primary underline transition-all hover:text-primary-light ${weightStyles} ${
                        fontSize as string
                    }`}
                    data-test-id={`${testId ?? nanoid()}-link`}
                    style={{
                        color: selectedColor,
                    }}
                >
                    {children}
                </button>
            )}
        </>
    );
};

